<template>
	<div class="page">
		<div class="title">
			<div class="titleL">会员订单记录</div>
			<div class="titleR">
				<el-button type="infor" @click="$router.back()">返回</el-button>
			</div>
		</div>
		<el-radio-group v-model="tabIndex" @change="changeTabIndex" style="padding:20px 20px 0 20px;">
			<el-radio-button :label="0">充值订单</el-radio-button>
			<el-radio-button :label="1">消费订单</el-radio-button>
			<el-radio-button :label="2">退换货订单</el-radio-button>
		</el-radio-group>
		<!-- 搜索栏 -->
		<div class="tabView">
			<div class="tabViewB">
				<div class="search">
					<div class="searchTitle">类型</div>
					<el-select v-model="grades" placeholder="请选择" style="width: 143px;">
						<el-option v-for="item in memberStarList" :key="item.id" :label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="search">
					<div class="searchTitle">日期</div>
					<el-select v-model="stock" placeholder="请选择" style="width: 143px;">
						<el-option v-for="item in stockOptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="tabViewBR">
					<!-- <el-button type="primary">修改积分</el-button> -->
					<!-- <el-button>导出</el-button>
					<el-button type="primary" >新建商品</el-button> -->
				</div>
			</div>
		</div>
		<!-- 表格 -->
		<div >
			<el-table :data="tableData" style="width: 100%">
				<el-table-column prop="date" label="日期">
				</el-table-column>
				<el-table-column prop="name" label="关联单号">
				</el-table-column>
				<el-table-column prop="province" label="类型">
				</el-table-column>
				<el-table-column prop="city" label="变化值">
				</el-table-column>
				<el-table-column prop="address" label="变动后积分">
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
	export default {
		components: {
		},
		data() {
			return {
				// table
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				tableData:[],
				// tab切换
				tabIndex:0,
			}
		},
		computed:{
			
		},
		mounted() {
			this.tableData()
		},
		methods: {
			getTableData(){
				let params = {
					"sex": 0,  // 性别 -1：全部、 0：女、1：男
					"applyType": "string", // 宠物类别 犬类；猫类
					"startTime": "string",
					"endTime": "string",
					"keyword": this.keyword,
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.post("/customer/Member/List",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.tableData = res.data.list
						this.page.total = res.data.count
					}
				})
			},
			// tab切换
			changeTabIndex(){
				console.log(this.tabIndex)
			}
			
		}
	}
</script>
<style lang="scss" scoped>
	.page {
		width:100%;height: 100%;border-radius: 20px;overflow: hidden;color: $fontColor;background-color: #fff;
		.title{
			display: flex;justify-content: space-between;border-bottom: 1px solid #f2f2f2;height: 68px;box-sizing: border-box;padding:14px 20px;
			.titleL{
				font-size: 16px;color:$fontColor;line-height: 40px;
			}
			.titleR{
				
			}
		}
		.tit{
			padding:32px 20px 8px;display: flex;justify-content: space-between;
			.titL{
				font-size: 16px;color:$fontColor;background: url(../../../assets/img/cangku/detailName.png) 2px 8px no-repeat;background-size: 24px 24px;
				.titText{
					padding-left:12px;display:inline-block;line-height: 40px;
				}
				.editImg{
					width:20px;height:20px;margin-left:8px;vertical-align: middle;cursor: pointer;
				}
			}
		}
		.msgBox{
			margin:0 20px;background-color: #F7F7F7;padding:10px 30px;box-sizing: border-box;border-radius: 20px;
			.msgItem{
				font-size: 14px;color:$fontColor;line-height: 40px;
				.editImg{
					width:20px;height:20px;margin-left:8px;vertical-align: middle;cursor: pointer;
				}
			}
		}
		// 搜索信息
		.tabView {
			height: 80px;
			border-radius: 20px;
			background: #fff;
			display: flex;
			flex-direction: column;
		
			.tabViewT {
				height: 80px;
				border-bottom: solid 1px #F2F2F2;
				display: flex;
				align-items: center;
		
				.tabItem {
					width: 116px;
					line-height: 40px;
					text-align: center;
					font-size: 16px;
					border-radius: 40px;
					margin-left: 20px;
					cursor: pointer;
					background: #FFF0E0;
				}
		
				.active {
					background: #F77E04;
					color: #fff;
				}
			}
		
			.tabViewB {
				
				flex: 1;
				display: flex;
				align-items: center;
				padding: 0 20px;
		
				.search {
					display: flex;
					align-items: center;
					margin-right: 32px;
		
					.searchTitle {
						font-size: 14px;
						padding-right: 4px;
						color: $fontColor;
					}
				}
		
				.tabViewBR {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}
			}
		}
		
	}
</style>
